<template>
  <div class="col-12 d-flex flex-column h-100 text-center overflow-hidden p-2 rounded-lg bg-white rounded-3 shadow-sm">
    <div class="row">
      <div class="col-12" v-if="offer.haveOffer">
        <span class="offer-tag on">{{offer.name}}</span>
        <span class="offer-tag ov">{{offer.value}}% OFF</span>
      </div>
      <div class="col-12">
        <b-img-lazy :src="item.imageUrl" />
      </div>
    </div>
    <div class="row justify-content-center flex-grow-1">
      <div class="col-12 py-3">
        <b class="text-black">{{item.name}}</b>
        <h5 class="py-2">
          <del class="m-0 text-danger" v-if="offer.haveOffer"><small>{{item.price}} €</small></del>
          <strong class="m-0 text-yellow">&nbsp;{{$helper.convertToCurrency($helper.afterOfferDiscount(item.realPrice,offer))}}</strong>
        </h5>
      </div>
    </div>
    <div class="form-row">
      <div class="col">
        <b-form-spinbutton min="1"
                           v-model="item.qty"
                           :value="item.qty"
                           max="10"></b-form-spinbutton>
      </div>
      <div class="col-auto">
        <b-button variant="yellow" block
                  @click="addToCart(item,offer)" :disabled="item.adding">
          <b-icon-arrow-clockwise v-if="item.adding" animation="spin" />
          <b-icon-cart-plus v-else />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShopItemCard",
  props : {
    item : Object,
    offer : Object
  },
  methods : {
    addToCart(item) {
      item.adding = true;
      const newPrice = this.$helper.afterOfferDiscount(item.realPrice,this.offer);
      const data = {
        item : item,
        price : newPrice,
        offer : this.offer
      }
      this.$store.commit("addToCart",data);
      setTimeout(()=>{
        item.adding = false;
        item.qty = 1;
      },500);
    }
  }
}
</script>

<style scoped>

</style>
