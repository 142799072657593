<template>
  <div class="col-12 h-100 overflow-hidden p-2 rounded-lg bg-white rounded-3 shadow-sm">
    <b-skeleton-img></b-skeleton-img>
    <div class="w-100 mt-3">
      <b class="text-black">
        <b-skeleton width="100%"></b-skeleton>
      </b>
      <h5 class="py-2">
        <strong class="m-0 text-yellow">
          <b-skeleton  width="100%"></b-skeleton>
        </strong>
      </h5>
      <div class=" py-1 text-center">
        <b-button variant="yellow" class="w-100">
          <b-skeleton width="100%"></b-skeleton>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "ShopItemCardLoading"
}
</script>

<style scoped>

</style>