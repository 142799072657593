<template>
  <div class="container-fluid py-5">
    <div class="row">
      <div class="container py-5 text-center text-white"
           v-if="(state==='loading' && !isAgainLoading )">
        <div-loader />
      </div>
      <div class="container py-5 text-center" v-else-if="state==='error'">
        <error-div :message="stateMessage" />
      </div>
      <div class="container" v-else>
        <div class="form-row pb-5">
          <div class="col-12 text-center text-white">
            <h2>{{category.name}}</h2>
            <p>{{category.info}}</p>
          </div>
        </div>
        <div class="form-row" v-if="!isAgainLoading">
          <div v-for="(item,index) in items" :key="'categories'+index"
               class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
            <shop-item-card :item="item" :offer="offer"/>
          </div>
        </div>
        <div class="form-row" v-else>
          <div v-for="(n,index) in currentTotal" :key="'categoriesLoading'+index"
               class="col-12 col-sm-3 mb-3 ">
            <shop-item-card-loading />
          </div>
        </div>

        <div class="form-row">
          <div class="col-12 py-3">
            <b-pagination pills
                          @input="loadAgain"
                          align="center"
                          size="lg"
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage">

              <template #ellipsis-text>
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
              </template>

            </b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DivLoader from "@/components/Template/DivLoader";
import ErrorDiv from "@/components/Template/ErrorDiv";
import ShopItemCard from "@/components/Shop/ShopItemCard";
import ShopItemCardLoading from "@/components/Shop/ShopItemCardLoading";
export default {
  name: "ShopCategoryDetail",
  components: {ShopItemCardLoading, ShopItemCard, ErrorDiv, DivLoader},
  created() {
    this.loadItems();
  },
  methods : {
    loadAgain() {
      this.isAgainLoading = true;
      this.loadItems();
    },
    loadItems() {
      this.state = "loading";
      this.stateMessage = "";
      this.$http.get("front/shopItems",{
        params : {
          category : this.$route.params.code,
          limit : this.perPage,
          page : this.currentPage
        }
      }).then(response=>{
        if(response.data.error===undefined) {
          this.state = 'error';
          this.stateMessage = this.$t("errors.unableToLoad");
        } else if(response.data.error) {
          this.state = 'error';
          this.stateMessage = response.data.message;
        } else {
          this.state = 'success';
          this.items = response.data.list;
          this.rows = response.data.total;
          this.category = response.data.category;
          this.offer = response.data.offer;
          this.currentTotal = this.items.length;
        }
      }).catch(()=>{
        this.state = 'error';
        this.stateMessage = this.$t("errors.unableToLoad");
      }).finally(()=>{
        this.isAgainLoading = false;
      });
    }
  },
  data() {
    return {
      currentPage : 1,
      rows : 0,
      perPage : 16,
      state : "loading",
      isAgainLoading : false,
      stateMessage : "",
      items : [],
      category : {},
      offer : {},
      currentTotal : 0
    }
  }
}
</script>

<style scoped>

</style>
